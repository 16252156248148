import React from 'react'

const EmailTemp = () => {
  return (
    <div style={{backgroundColor:'#F6A835',padding:'30px',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div style={{backgroundColor:'white',borderRadius:'10px',padding:'30px'}}>
        <img src='http://admin.proshelf.net/static/media/logo1.414f9ca303c0509a7501.png' width={'150px'} height={'80px'} style={{objectFit:'contain'}}/>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'0px 0px 20px 0px'}}>
                <img src='https://cdn-icons-png.flaticon.com/512/10646/10646637.png' width={'80px'} height={'80px'} style={{objectFit:'contain'}}/>
                
            </div>
            <h3 style={{textAlign:'center'}}>Here is your One Time Password</h3>
            <p style={{textAlign:'center'}}>to validate your email address</p>
            <div style={{margin:'30px 0px'}}>
            <h3 style={{textAlign:'center',letterSpacing:'18px',fontSize:'40px'}}>3214</h3>
            </div>
        <p style={{fontSize: "16px", lineHeight: 1.6, marginBottom: "20px",textAlign:'center'}}>Thank you for your requesting.</p>
        {/* <p style={{fontSize: "16px", lineHeight: 1.6, marginBottom: "0px"}}>Regarding,<br/>Proshelf</p> */}
        </div>
        
    </div>
  )
}

export default EmailTemp